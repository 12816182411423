import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2870615501/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { FormProps } from '../../components/FormProps';
export const name = 'SpaceForm';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  name,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{name}</h2>
    <FormProps fields={[{
      name: 'username',
      type: 'text',
      description: 'This field should not be mutable'
    }, {
      name: 'afterLogoutPath',
      type: 'text'
    }, {
      name: 'bundlePath',
      type: 'text'
    }, {
      name: 'defaultDatastoreFormConfirmationPage',
      type: 'text'
    }, {
      name: 'defaultDatastoreFormDisplayPage',
      type: 'text'
    }, {
      name: 'defaultLocale',
      type: 'text'
    }, {
      name: 'defaultTimezone',
      type: 'text'
    }, {
      name: 'displayType',
      type: 'select'
    }, {
      name: 'displayValueJSP',
      type: 'text'
    }, {
      name: 'displayValueRedirect',
      type: 'text'
    }, {
      name: 'displayValueSPA',
      type: 'text'
    }, {
      name: 'displayValue',
      type: 'text'
    }, {
      name: 'loginPage',
      type: 'text'
    }, {
      name: 'name',
      type: 'text'
    }, {
      name: 'resetPasswordPage',
      type: 'text'
    }, {
      name: 'oauthSigningKey',
      type: 'password'
    }, {
      name: 'changeOAuthSigningKey',
      type: 'checkbox'
    }, {
      name: 'sessionInactiveLimitInSeconds',
      type: 'text'
    }, {
      name: 'sharedBundleBase',
      type: 'text'
    }, {
      name: 'slug',
      type: 'text'
    }, {
      name: 'linked',
      type: 'checkbox'
    }, {
      name: 'trustedFrameDomains',
      type: 'text-multi'
    }, {
      name: 'trustedResourceDomains',
      type: 'text-multi'
    }, {
      name: 'securityPolicies',
      type: null
    }, {
      name: 'attributesMap',
      type: 'attributes'
    }]} formOptions={[]} dataSources={[{
      name: 'space',
      type: 'Space'
    }, {
      name: 'locales',
      type: 'Locale[]'
    }, {
      name: 'timezones',
      type: 'Timezone[]'
    }, {
      name: 'attributeDefinitions',
      type: 'AttributeDefinition[]'
    }, {
      name: 'securityPolicyDefinitions',
      type: 'SecurityPolicyDefinition[]'
    }]} mdxType="FormProps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      